import React from "react";
import styled from "styled-components";

const HistoryClientsSupportCasesHotels = () => {
	return (
		<HistoryClientsSupportCasesHotelsWrapper>
			<div>History Clients Support Cases Content</div>
		</HistoryClientsSupportCasesHotelsWrapper>
	);
};

export default HistoryClientsSupportCasesHotels;

const HistoryClientsSupportCasesHotelsWrapper = styled.div``;
