import React from "react";
import styled from "styled-components";

const HistoryClientsSupportCases = () => {
	return (
		<HistoryClientsSupportCasesWrapper>
			<div>History Clients Support Cases Content</div>
		</HistoryClientsSupportCasesWrapper>
	);
};

export default HistoryClientsSupportCases;

const HistoryClientsSupportCasesWrapper = styled.div``;
