import React from "react";
import styled from "styled-components";

const ActiveClientsSupportCases = () => {
	return (
		<ActiveClientsSupportCasesWrapper>
			<div>Active Clients Support Cases Content</div>
		</ActiveClientsSupportCasesWrapper>
	);
};

export default ActiveClientsSupportCases;

const ActiveClientsSupportCasesWrapper = styled.div``;
